//------------------------------------------------------------------
//	BOOTSTRAP SETUP
//------------------------------------------------------------------
@import 'common/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/badge';
@import 'common/typography';

$select-border: 0;
$select-border-focus: 0;
$select-border-active: 0;
$select-padding: 0;
$select-options-border: 1px solid #ccc;

//------------------------------------------------------------------
//	CORE ADMIN STYLES
//------------------------------------------------------------------
@import 'node_modules/mlmi-library/components/admin/v1/admin';
@import 'node_modules/mlmi-library/components/form/v1/form';
@import 'node_modules/mlmi-library/components/select/v1/select';

.layout[data-layout='block_row'] {
  overflow: visible;

  .acf-field[data-name='text_content_1'] {
    .acf-editor-wrap {
      margin-top: -24px;
    }
  }
}

.wp-admin {
  &.post-type-project:not(.taxonomy-project_category),
  &.post-type-news {
    .acf-tooltip a[data-layout='text_row'],
    .acf-tooltip a[data-layout='grid'],
    .acf-tooltip a[data-layout='accordion'] {
      display: none !important;
    }
  }

  &.post-type-project {
    .acf-tooltip a[data-layout='case_study_row'] {
      display: none !important;
    }
  }
}

.mlmi-builder-row .layout[data-layout='case_study_row'] {
  border-color: #1d2327 !important;

  > .acf-fc-layout-handle {
    background: #1d2327 !important;
  }
}

.acf-field[data-name='block_layout'] {
  select {
    display: none;
  }

  .select {
    max-width: 92px;

    &__selected {
      background: #fff;
      top: 0;
      left: 0;

      &::after {
        display: none;
      }
    }

    &__options {
      width: 450px;
      background: $white;
      border-radius: 3px;
      box-shadow: 3px 3px 5px rgba($black, .05);

      ul {
        width: 100%;
        display: flex;
        flex-flow: wrap;

        li {
          flex: 20% 0 0;
          padding: 10px 0;
          text-align: center;
          margin: 0;

          &:hover {
            background-color: #eee;
          }

          img {
            line-height: 60px;
            vertical-align: middle;
            pointer-events: none;
          }
        }
      }
    }
  }
}

.layout[data-layout='image_map_row'] {
  .acf-field[data-name='map_points'] {
    th.acf-th[data-key='field_634e1476c39c7'],
    th.acf-th[data-key='field_634e14aac39c8'] {
      display: none !important;
    }

    .smaller-editor > .acf-input {
      margin-top: -7px;
    }
  }

  .acf-field[data-name='map_image'] .image-wrap {
    width: 100% !important;
    max-width: 1024px !important;
    overflow: hidden;

    > img {
      pointer-events: none;
    }

    .map-point {
      position: absolute !important;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 1px;
      cursor: grab;

      &::before {
        @include font-regular;
        content: attr(data-index);
        display: block;
        width: 33px;
        height: 33px;
        width: 33px;
        line-height: 33px;
        font-size: 14px;
        text-align: center;
        border: 1px solid $black;
        background: transparent;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        pointer-events: all;
        background: $white;
      }
    }
  }
}
