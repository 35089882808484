/* Font families */

@font-face {
    font-family: 'Gellix';
    src:  url('/font/Gellix-Regular.woff2') format('woff2'),
        url('/font/Gellix-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Gellixregular_italic';
  src: url('/font/Gellix-Regular-Italic.woff2') format('woff2'),
       url('/font/Gellix-Regular-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gellixmedium';
  src:  url('/font/Gellix-Medium.woff2') format('woff2'),
      url('/font/Gellix-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gellixmedium_italic';
  src: url('/font/Gellix-Medium-Italic.woff2') format('woff2'),
       url('/font/Gellix-Medium-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
    font-family: 'Gellixsemibold';
    src:  url('/font/Gellix-SemiBold.woff2') format('woff2'),
        url('/font/Gellix-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Gellixsemibold_italic';
  src: url('/font/Gellix-SemiBold-Italic.woff2') format('woff2'),
       url('/font/Gellix-SemiBold-Italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

/* Available fonts */
@mixin font-regular {
  font-family: Gellix, sans-serif;
  font-weight: 400;
}

@mixin font-regular-italic {
  font-family: Gellixregular_italic, sans-serif;
  font-weight: 400;
}

@mixin font-medium {
  font-family: Gellixmedium, sans-serif;
  font-weight: 500;
}

@mixin font-medium-italic {
  font-family: Gellixmedium_italic, sans-serif;
  font-weight: 500;
}

@mixin font-semibold {
  font-family: Gellixsemibold, sans-serif;
  font-weight: 600;
}

@mixin font-semibold-italic {
  font-family: Gellixsemibold_italic, sans-serif;
  font-weight: 600;
}

@mixin default-fonts {
  .font-regular {
    @include font-regular;
  }

  .font-regular-italic {
    @include font-regular-italic;
  }

  .font-medium {
    @include font-medium;
  }

  .font-medium-italic {
    @include font-medium-italic;
  }

  .font-semibold {
    @include font-semibold;
  }

  .font-semibold-italic {
    @include font-semibold-italic;
  }
}

/* Heading styles */
@mixin heading-main {
  @include font-semibold;
  @include antialias;
  @include responsive-text(40, 25);
  line-height: 1.125em;
  text-transform: uppercase;
  letter-spacing: 0.020em;
}

@mixin heading-medium {
  @include font-semibold;
  @include antialias;
  @include responsive-text(20, 20);
  line-height: 1.4em;
  text-transform: uppercase;
  letter-spacing: 0.010em;
}

@mixin heading-big {
  @include font-semibold;
  @include antialias;
  @include responsive-text(110, 32);
  line-height: 1.090em;
  text-transform: uppercase;
  letter-spacing: 0.010em;
}

@mixin default-heading-styles {
  .heading-main {
    @include heading-main;
  }

  .heading-medium {
    @include heading-medium;
  }

  .heading-big {
    @include heading-big;
  }
}

/* Text styles */
@mixin text-regular {
  @include font-regular;
  @include responsive-text(16, 16);
  line-height: 1.5em;
  letter-spacing: 0.010em;
}

@mixin text-intro {
  @include font-regular;
  @include responsive-text(30, 25);
  @include uppercase;
  line-height: 1.2667em;
  letter-spacing: 0.02em;
}

@mixin text-quote {
  @include font-regular;
  @include responsive-text(22, 18);
  line-height: 1.3535em;
  letter-spacing: 0.01em;
  max-width: 560px;
  margin-left: 40px;
}

@mixin text-large {
  @include font-semibold;
  @include responsive-text(44, 20, 36);
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0.005em;
}

@mixin text-caption {
  @include font-regular;
  @include responsive-text(14, 10);
  line-height: 1.333em;
  letter-spacing: 0.005em;
}

@mixin default-text-styles {
  .text-regular {
    @include text-regular;
  }

  .text-intro {
    @include text-intro;
  }

  .text-quote {
    @include text-quote;
  }

  .text-large {
    @include text-large;
  }

  .text-caption {
    @include text-caption;
  }
}
